<template>
  <!-- <div class="wrapper">
    <StaticPart />
   
  </div> -->
  <Toast v-if="show" :message="toastMessage" />
</template>

<script>
// import StaticPart from '../home/StaticPart'
import { reactive, toRefs } from "vue";
import { useRouter, useRoute } from "vue-router";
import { post } from "../../utils/request";
import Toast, { useToastEffect } from "../../components/Toast";
import { onMounted } from "vue";
import { sendWSPush } from "@sven0706/websocket";
import { useStore } from 'vuex';

// 处理注册逻辑
const useLoginEffect = (showToast) => {
  const router = useRouter();
  const route = useRoute();
  const store = useStore()
  const data = reactive({
    token: route.params.token,
    setting: route.params.setting,
    theme1: route.params.theme1,
    theme2: route.params.theme2,
    theme3: route.params.theme3,
    wifi: route.params.wifi,
  });
  console.log(data.token);
  console.log("setting", data.setting);
  const handleLogin = async () => {
    try {
      const result = await post("QRlogin", {
        token: data.token,
      });
      if (result?.errno === 0) {
        sessionStorage.isLogin = true;
        sessionStorage.token = result?.token;
        sessionStorage.id = result?.id;
        sessionStorage.setting = result?.setting;
        sessionStorage.theme1 = result?.theme1;
        sessionStorage.theme2 = result?.theme2;
        sessionStorage.theme3 =result?.theme3;
        sessionStorage.wifi=result?.wifi;

        const setinitData = {
          setting: result?.setting,
          theme1: result?.theme1,
          theme2: result?.theme2,
          theme3: result?.theme3,
          wifi: result?.wifi,
        };

        store.commit('changeInitData', { setinitData });

        // sessionStorage.setting = result?.setting;
        // sessionStorage.theme1 = result?.theme1;
        // sessionStorage.theme2 = result?.theme2;
        // sessionStorage.theme3 = result?.theme3;
        // sessionStorage.wifi = result?.wifi;

        const lidata = { code: 5001, uid: result?.id, token: result?.token };
        sendWSPush(lidata);
        router.push({ name: "panel" });
      } else {
        console.log(result);
        showToast("登陆失败");
      }
    } catch (e) {
      showToast("请求失败");
    }
  };

  const { token } = toRefs(data);
  return { token, handleLogin };
};

export default {
  name: "Qlogin",
  components: { Toast },
  // 职责就是告诉你，代码执行的一个流程
  setup() {
    onMounted(() => {
      handleLogin();
    });

    const { show, toastMessage, showToast } = useToastEffect();
    const { handleLogin, token } = useLoginEffect(showToast);

    return {
      token,
      show,
      toastMessage,
      handleLogin,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  overflow-y: auto;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0.5rem;
  right: 0;
  padding: 0 0.18rem 0.1rem 0.18rem;
}
</style>